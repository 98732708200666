import { getPlanning } from '@/services/driver/missionsOrders';

const selectorPrefix = '.tabs-inner .ion-page:not(.ion-page-hidden)';

async function requestForRefresh() {
  if (document.hidden) {
    return;
  }

  let refresher = document.querySelector(`${selectorPrefix} .refresher-detail`);
  if (!refresher) {
    refresher = document.querySelector(`${selectorPrefix} .refresher`);
  } else {
    await getPlanning();
  }

  if (refresher) {
    refresher.click();
  }
}

async function refreshDataPlanning() {
  setInterval(requestForRefresh, 60000);
}

export { refreshDataPlanning };
